/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import styled from 'styled-components'

import { MediaComponent } from '../../MediaComponent/MediaComponent';


const ProjectInformation = (props) => {
  
    return (
      <Container className="spb-8 border-top">
        <Row className="sp-5">
          <Col lg="6" md="10" className="offset-lg-3 offset-md-1">
            <h2>
              { props.data.projectInfoTitle }
            </h2>
            <p>
              { props.data.bodyDescription.bodyDescription }
            </p>
          </Col>
        </Row>
        <Row>
            <Col sm="12 smallteam-grid">
                { props.data.informationImages[0].imageItem.map(item => {
                    return (
                        <div>
                            <Image fluid={item.fluid} />
                        </div>
                    )
                })} 
            </Col>     
        </Row>
        <Row className="spt-5">
            <Col>
                <MediaComponent heroImage={props.data.imageScreenshot} embedVideo={props.data.imageScreenshotEmbedVideo} autoPlay={props.data.firstAutoplay} />
            </Col>
        </Row>
        <Row className="spt-10">
          <Col lg="6" md="10" className="offset-md-1 offset-lg-3">
            <h2>
              { props.data.projectSecondTitle }
            </h2>
            <p>
              { props.data.projectInfoDescription.projectInfoDescription }
            </p>
          </Col>
        </Row>
        <Row className="spt-5">
            <Col>
                <MediaComponent heroImage={props.data.secondScreenschoot} embedVideo={props.data.secondScreenshotEmbedVideo} autoPlay={props.data.secondAutoplay} />
            </Col>
        </Row>
      </Container>
    )
  }


export default ProjectInformation