import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout'


import {
    HeroProjectStudio,
    ProjectDescription,
    TestimonialStudio,
    ProjectOutcomes,
    ProjectInformation,
    StartupSection,
    ProjectDemoDay,
    ProjectRecognition,
    ProjectGetInTouch,
    ProjectCallToAction,
} from '../components/StudioProjectSections'


import { Helmet } from 'react-helmet'


const StudioProject = props => {

const manyReferencesSection = props.data.contentfulStudioCasePage.manyReferencesSection;
const caseslug = props.data.contentfulStudioCasePage.slug;
const pageTitle = props.data.contentfulStudioCasePage.internalPageName;
const contentColourScheme = props.data.contentfulStudioCasePage.manyReferencesSection[0].contentColor;
  
  
const activeStateColour = props.data.contentfulStudioCasePage.manyReferencesSection[0].activeStateColour

  
  return (
    <div className={"body casestudy " + caseslug + " " + contentColourScheme}>
      <Layout activeStateColour={activeStateColour}>
        <Helmet>
          <title>Urbian | {pageTitle}</title>
        </Helmet>
        {manyReferencesSection.map(section => {
            return (
                <div className={section.id} key={section.id}>
                    {section.__typename === 'ContentfulHeroSectionCases' && (
                        <HeroProjectStudio data={section} /> 
                    )}
                    {section.__typename === 'ContentfulProjectDescription' && (
                        <ProjectDescription data={section} />
                    )}
                    {section.__typename === 'ContentfulProjectTestimonial' && (
                        <TestimonialStudio data={section} />
                    )}
                    {section.__typename === 'ContentfulProjectOutcomes' && (
                        <ProjectOutcomes  data={section}/>
                    )}
                    {section.__typename === 'ContentfulProjectInformation' && (
                        <ProjectInformation data={section} />
                    )}
                    {section.__typename === 'ContentfulStartupSection' && (
                        <StartupSection data={section} />
                    )}
                    {section.__typename === 'ContentfulDemoDayProject' && (
                        <ProjectDemoDay data={section}/>
                    )}
                    {section.__typename === 'ContentfulRecognitionProjectSection' && (
                        <ProjectRecognition data={section}/>
                    )}
                    {section.__typename === 'ContentfulGetintouchProjectSection' && (
                        <ProjectGetInTouch 
                          data={section}
                          location={props.location.pathname}
                        />
                    )}
                    
                </div>
            )
        })}
      <ProjectCallToAction />
      </Layout>
    </div>
  );
};

export default StudioProject;

export const contentfulStudioProjectePage = graphql`
  query StudioProjectPageQuery($slug: String!) {
    contentfulStudioCasePage(slug: { eq: $slug }) {
        slug
        internalPageName
        manyReferencesSection {
          ... on ContentfulHeroSectionCases {
            __typename
            id
            title
            tag
            tagDestinationUrl
            activeStateColour
            activeStateTextColour
            contentmediaOverlap
            addInAnOverlay
            backgroundColor
            contentColor
            backgroundImage {
              title
              file {
                url
              }
            }
            embedVideo
            autoplayVideo
            heroImage {
                fluid(maxWidth: 800){
                    ...GatsbyContentfulFluid_withWebp
                }
                file {
                  url
                  fileName
                  contentType
                }
                id
            }
          }
          ... on ContentfulProjectDescription {
            __typename
            id
            bodyDescription {
              bodyDescription
            }
            projectLink
            workingDetails
            clientTitle
            projectUrlLabel
            titleDescriptions {
              title
              descriprions
            }
          }
          ... on ContentfulProjectTestimonial {
            __typename
            id
            textTestimonial
            testimonailAuthor
          }
          ... on ContentfulProjectOutcomes {
            __typename
            id
            name
            titleDescriptions {
              descriprions
              title
            }
          }
          ... on ContentfulProjectInformation {
            __typename
            projectInfoTitle
            bodyDescription {
              bodyDescription
            }
            imageScreenshot {
              file {
                url
                fileName
                contentType
              }
              fluid(maxWidth: 1400){
                ...GatsbyContentfulFluid_withWebp
              }
            }
            informationImages {
              imageItem {
                id
                fluid(maxWidth: 500){
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
            projectSecondTitle
              projectInfoDescription {
                projectInfoDescription
            }
            firstAutoplay
            secondAutoplay
            imageScreenshotEmbedVideo
            secondScreenshotEmbedVideo
            secondScreenschoot {
              file {
                url
                fileName
                contentType
              }
              fluid(maxWidth: 1400){
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          ... on ContentfulStartupSection {
            __typename
            id
            sectionTitle

            sectionDescription {
              sectionDescription
            }
            startupItemComponent {
              AdditionalsupportingVideo
              startupindivautoplayVideo
              startupIndivloopVideo
              reversed
              
              videoIntroduction {
                childMarkdownRemark {
                  html
                }
              }
              secondTitle
              secondDescription {
                secondDescription
              }
              startupDescription {
                startupDescription
              }
              startupTitle
              startupImage {
                fluid(maxWidth: 1400){
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              thirdDescription
              thirdTitle
            }
          }
          ... on ContentfulDemoDayProject {
            __typename
            id
            blockImages {
              imageItem {
                fluid(maxWidth: 600){
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
            demodayautoPlayVideo
            demodayloopVideo
            demoDayMainVideo
            additionalDemoloopVideo
            additionaldemoautoplayVideo
            FinaladditionalVideo
            title
            cta
            titleSecond
            mainImageDescription
            description {
              description
            }
            descriptionSecond {
              descriptionSecond
            }
            featuredImage {
              fluid(maxWidth: 1400){
                ...GatsbyContentfulFluid_withWebp
              }
              description
            }
            mainImage {
              fluid(maxWidth: 1400){
                ...GatsbyContentfulFluid_withWebp
              }
              description
            }
          }
          ... on ContentfulRecognitionProjectSection {
            __typename
            id
            recognitionItems {
              title
              callToAction
              slugItem
              imageTitleDescription {
                id
                title
                file {
                  url
                }
              }
            }
            title
          }
          ... on ContentfulGetintouchProjectSection {
            __typename
            email
            titleSecond
            title
            description {
              description
            }
            descriptionSecond {
              descriptionSecond
            }
            nextProjectSlud
          }
        }
        id
    }
  }
`;
