/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import SectionSideBySide from '../../BlockSections/SectionSideBySide';
import { MediaComponent } from '../../MediaComponent/MediaComponent';
import styled from 'styled-components'

const StartupSection = (props) => {
    
    return (
      <div className="section-b showcase-se">
        <Container>
          <Row className="spb-8">
            <Col lg="6" md="10" className="offset-lg-3 offset-md-1">
              <h2>{props.data.sectionTitle}</h2>
              <p>{props.data.sectionDescription.sectionDescription}</p>
            </Col>
          </Row>
          <Row>
              { props.data.startupItemComponent.map((currElement, index) => {
                    const stUpLength = props.data.startupItemComponent.length
                    
                    return (
                      <div class="sp-8 col-md-10 offset-md-1 sp-3">
                           
                        <SectionSideBySide
                          textSide={
                            <Col>
                              <span className="counter">Startup {index+1} of {stUpLength}</span>
                              <h3>{currElement.startupTitle}</h3>
                              <p>{currElement.startupDescription.startupDescription}</p> 
                              <strong>{currElement.secondTitle}</strong>
                              <p>{currElement.secondDescription.secondDescription}</p>
                              <strong>{currElement.thirdTitle}</strong>
                              <p>{currElement.thirdDescription}</p>
                              {
                                currElement.videoIntroduction !== null && (
                                  <div dangerouslySetInnerHTML={{ __html: `<p> ${currElement.videoIntroduction.childMarkdownRemark.html} </p>` }} />
                                )
                              }
                            </Col>
                          }
                          imageSide={
                            <Col>
                              <Image alt="{currElement.startupTitle}" fluid={currElement.startupImage.fluid} />
                            </Col>
                          }
                          reversed={currElement.reversed}
                          
                        /> 
                        <WrapperMedia>
                          <MediaComponent heroImage={currElement.featuredImage} embedVideo={currElement.AdditionalsupportingVideo} autoPlay={currElement.startupindivautoplayVideo} />   
                        </WrapperMedia>
                        
                      </div>
                    )
              })}  
          </Row>
        </Container>
      </div>
    )
  }

export const WrapperMedia = styled.div`
  margin-top:32px;
  padding:0 30px;
`

export default StartupSection