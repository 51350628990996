/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'gatsby'
import { SectionLayout } from '../../SectionLayout/SectionLayout';
import { MediaComponent } from '../../MediaComponent/MediaComponent';
import styled from 'styled-components';
import Image from 'gatsby-image';

const HeroProjectStudio = (props) => {
  const activehoverhuecolour = props.data.activeStateTextColour;
  const activehoverhue = props.data.activeStateColour;
  
  let lightTheme = {
    colorTextCTA:"white"
  }

  let darkTheme = {
    colorTextCTA:"#030303"
  }

  let colorTextCTA

  if(props.data.contentColor === 'light') {
    colorTextCTA = lightTheme.colorTextCTA
  } else if(props.data.contentColor === 'dark') {
    colorTextCTA = darkTheme.colorTextCTA
  }

  let MediaOverlap 

  if(props.data.contentmediaOverlap == 1) {
    MediaOverlap = "overlap"
  } else {
    MediaOverlap = ""
  }

  

  

  let ImageOverlay 

  if(props.data.addInAnOverlay == 1) {
    ImageOverlay = "bgcover overlay"
  } else {
    ImageOverlay = ""
  }

  
  
return (
    <SectionLayout
      className={ImageOverlay}
      bgColor={props.data.backgroundColor}
      backgroundImg={props.data.backgroundImage}
    >
      <Container className="scontent-wrap">
         <Row className="sp-5">
            <Col md="8" lg="7">
              <CallToActionHero 
                  className="casestudycategory" 
                  activehoverhue={activehoverhue} 
                  activehoverhuecolour={activehoverhuecolour}
                  to={`/services/${props.data.tagDestinationUrl}`}
              >
                  { props.data.tag }
              </CallToActionHero>
              <HeroTitle colorTextCTA={colorTextCTA} >
                  { props.data.title }
              </HeroTitle>
            </Col>
        </Row>
        <div className={"project-media " + MediaOverlap}>
          <MediaComponent heroImage={props.data.heroImage} embedVideo={props.data.embedVideo} autoPlay={props.data.autoplayVideo} />
        </div>
      </Container>
    </SectionLayout>   
  )
}

export const HeroTitle = styled.h1`
  color:${props => props.colorTextCTA};
`

export const CallToActionHero = styled(Link)`
  :hover {
    background: ${props => props.activehoverhue};
    border-color: ${props => props.activehoverhue} !important;
    color: ${props => props.activehoverhuecolour};
  }
`

export const StyledContainer = styled(Container)`

`

export const ContainerIframe = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; 
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
   
  }
`

export const WrapperIframe = styled.div``

export default HeroProjectStudio