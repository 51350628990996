import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'

const ProjectDescription = (props) => {
return (
    <Container className="project-desp">
      <Row className="spb-8">
          <Col sm="5">
            <strong>Client</strong>
            <span> { props.data.clientTitle }</span>
            {
              props.data.projectLink !== undefined && (
                <>
                  <strong className="mt-5">View project</strong>
                  <a
                    href={props.data.projectLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={props.data.clientTitle}
                  >
                    {props.data.projectUrlLabel }
                  </a>
                </>
              )
            }
          </Col>
          <Col sm="6" className="offset-sm-1 pt-2">
              <p>{ props.data.bodyDescription.bodyDescription }</p>
          </Col>
        </Row>
        <Row className="border-top border-bottom project-details spb-5">
          { props.data.titleDescriptions.map(detail => {
            return (
              <Col lg="3" md="6">
                <h6>{detail.title}</h6>
                {detail.descriprions.map(info => {
                  return (
                    <li>{info}</li>
                  ) 
                })}
              </Col>
            )
          })}          
        </Row>
    </Container>
  )
}



export default ProjectDescription
