/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'gatsby'

import img1 from '../../../assets/images/ventureburr-logo.jpg'
import img2 from '../../../assets/images/Bizcommunity-logo.png'
import img3 from '../../../assets/images/africa-logo.png'

const ProjectRecognition = (props) => {
    
    return (
      <Container className="sp-5 recognition">
        <Row>
          <Col lg="6">
            <h2>{props.data.title}</h2>
          </Col>
          <Col lg="6">
            { props.data.recognitionItems.map(detail => {
                
                return (
                <div className="border-bottom pb-5 mb-5">
                    <div className="recog-wrapper">
                      <img src={detail.imageTitleDescription.file.url} alt={detail.imageTitleDescription.title} />
                    </div>
                    <p>"{detail.title}"</p>
                    <a className="styled__Link-lfEBUk iUBhrC" href={`${detail.slugItem}`} target="_blank">
                      {detail.callToAction}
                      <svg
                        className="styled__Arrow-fdeFVt fRPwvC"
                        width="14"
                        height="10"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g stroke="#030303" fill="none" fillRule="evenodd">
                          <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                          <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                        </g>
                      </svg>
                    </a>  
                </div>
                )
            })}   
          </Col>
        </Row>
      </Container>
    )
  }


export default ProjectRecognition
