import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'

const TestimonialStudio = (props) => {
  


return (
    <Container>
        <Row className="spb-8 testimonial">
          <Col>
            <h2>
                {props.data.textTestimonial}
            </h2>
            <span className="attribution">
                {props.data.testimonailAuthor}
            </span>
          </Col>
        </Row>
    </Container>
  )
}



export default TestimonialStudio
