import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'gatsby'
import { MediaComponent } from '../../MediaComponent/MediaComponent'
import Image from 'gatsby-image'

const ProjectDemoDay = (props) => {
    
    return (
      <Container className="spt-8">
        <Row>
          <Col className="offset-md-1 offset-lg-3 col-lg-6 col-md-10">
            <h2>
              {props.data.title}
            </h2>
            <p>
               {props.data.description.description}
            </p>
          </Col>
        </Row>
        <Row className="spt-5">
          <Col>
              
              <MediaComponent heroImage={props.data.mainImage} embedVideo={props.data.demoDayMainVideo} autoPlay={props.data.demodayautoPlayVideo} />
              
              { props.data.mainImageDescription && (
                <span className="img-text">
                  {props.data.mainImageDescription}
                </span>
              )}
          </Col>
        </Row>
        <Row className="spt-8">
          <Col className="offset-md-1 offset-lg-3 col-lg-6 col-md-10">
            <h2>
                {props.data.titleSecond}
            </h2>
            <p>
                {props.data.descriptionSecond.descriptionSecond}
            </p>
            <Link
              className="styled__Link-lfEBUk iUBhrC font-weight-normal"
              to="/contact"
            >
               {props.data.cta}
              <svg
                className="styled__Arrow-fdeFVt fRPwvC"
                width="14"
                height="10"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g stroke="#030303" fill="none" fillRule="evenodd">
                  <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                  <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                </g>
              </svg>
            </Link>
          </Col>
        </Row>
        <Row className="spt-5 sp-8">
            <Col sm="12 smallteam-grid">
                { props.data.blockImages.imageItem.map(item => {
                    return (
                        <div>
                            <Image fluid={item.fluid} />
                        </div>
                    )
                })}
                
            </Col> 
            
        </Row>
        <MediaComponent heroImage={props.data.featuredImage} embedVideo={props.data.FinaladditionalVideo} autoPlay={props.data.additionaldemoautoplayVideo} />     
      </Container>
    )
  }


export default ProjectDemoDay