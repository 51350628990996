/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import './styled.css'

const ProjectOutcomes = (props) => {

    return (
      <Container className="outcomes border-top spb-5">
        <Row>
          <Col lg="5">
            <h2>{ props.data.name }</h2>
          </Col>
          <Col lg="7">
            <Row>
                { props.data.titleDescriptions.map(detail => {
                    return (
                    <Col md="6">
                        <strong>{detail.title}</strong>
                        {detail.descriprions.map(info => {
                        return (
                            <span>{info}</span>
                        ) 
                        })}
                    </Col>
                    )
                })}  
            </Row>
          </Col>
        </Row>
      </Container>
    )
}


export default ProjectOutcomes