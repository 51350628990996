/* eslint-disable */
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'gatsby';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import Form from 'react-bootstrap/Form';

class ProjectGetintouch extends React.Component {
  constructor() {
    super();
    this.state = {
      email: null,
      message: '',
    };

    this.__handleChange = this._handleChange.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
  }

  _handleChange = (e) => {
    e.preventDefault();

    this.setState({
      [`${e.target.name}`]: e.target.value,
    });
  };

  _handleSubmit = (e) => {
    e.preventDefault();
    addToMailchimp(this.state.email)
      .then(({ msg, result }) => {
        if (result !== 'success') {
          throw msg;
        }
        navigate('/subscription-thank-you');
      })
      .catch((err) => {
        this.setState(function(state, props) {
          return {
            message: err,
          };
        });
      });
  };
  render() {
    return (
      <div>
        <Container className='border-top spb-5'>
          <Row>
            <Col lg='6'>
              <h2>Get in touch</h2>
            </Col>
            <Col lg='6'>
              <p>
                Interested in learning more about the Social Tech Startup
                Challenge or any other projects? <br />
                Reach out to
                <a href='mailto:newbiz@urbian.io' className='yellowBackground'>
                  {' '}
                  newbiz@urbian.io
                </a>
              </p>
            </Col>
          </Row>
        </Container>
        <div className='nextup dark'>
          <Container>
            <Row className='align-items-center'>
              <Col className='offset-md-3 offset-lg-4' md='6' lg='4'>
                <div className='form-div'>
                  <p>Stay in the loop with our quarterly newsletter </p>
                  <Form
                    className='mt-5'
                    name='subscribe'
                    onSubmit={this._handleSubmit}
                  >
                    <input type='hidden' name='email' value='subscribe' />
                    <Form.Group controlId=''>
                      <Form.Control
                        type='email'
                        placeholder='Your email'
                        name='email'
                        onChange={this._handleChange}
                        required
                      />
                    </Form.Group>
                    <button
                      type='submit'
                      class='styled__Button-ksqKNN iyLmoo styled__Button-kGpwPX dQtCFI form-contact-button'
                    >
                      Send
                      <svg
                        className='Arrow__Svg-gllSXh SARKW styled__Arrow-ihTfeJ cFLifL'
                        width='14'
                        height='10'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g stroke='#ffffff' fill='none' fillRule='evenodd'>
                          <path d='M8.5.964L13.036 5.5 8.5 10.036'></path>
                          <path d='M12.5 5.5H.5' strokeLinecap='square'></path>
                        </g>
                      </svg>
                    </button>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default ProjectGetintouch;
