import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { isNil } from '../../../utility'

import PropTypes from 'prop-types';
import styled from 'styled-components';

export const SectionLayout = ({
  children,
  backgroundImg,
  bgColor,
  backgroundColor,
  ...otherProps
}) => {
  const data = useStaticQuery(graphql`
    query {
        allContentfulWebsiteImages(
        filter: {
          contentful_id: {
            in: "6ZhUaB0PXc59aZVECUZAw2"
          }
        }
      ) {
        edges {
          node {
            backgroundImage {
              title
              file {
                url
              }
            }
          }
        }
      }
    }
  `);

  const pageImages = data.allContentfulWebsiteImages;
  if(!bgColor) {
    bgColor = "transparent"
  }

  let backgroundDefault;
  pageImages.edges.forEach(imageItem => {
    if (imageItem.node.backgroundImage.title === 'Default background') {
        backgroundDefault = !isNil(imageItem.node.backgroundImage) ? imageItem.node.backgroundImage.file.url : '';
    } 
  });

  let backgroundImgSection
  
  if(!backgroundImg && !bgColor) {
    backgroundImgSection = backgroundDefault
  } else if(backgroundImg) {
    backgroundImgSection = backgroundImg.file.url
  } else if(bgColor) {
    backgroundImgSection = ''
  }


  return (
    <Layout
      bgColor={bgColor}
      backgroundImgSection={backgroundImgSection}
      {...otherProps}
    >
      {children}
    </Layout>
  );
};
export default SectionLayout;
SectionLayout.propTypes = {
  backgroundImg: PropTypes.string, // background image
  backgroundColor: PropTypes.string
};


const Layout = styled.div`
  background-image: ${props =>
    `url(${props.backgroundImgSection});`}
  background-color: ${props => props.bgColor};
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  background-repeat: no-repeat;
`;