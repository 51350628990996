import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import 'bootstrap/dist/css/bootstrap.min.css'
import { Row, Col } from 'react-bootstrap'

export default function SectionSideBySide({
  reversed,
  textSide,
  imageSide,
  align,
  justify,
  maxWidth,
  ...otherProps
}) {

  
    return (
      <>
        <SectionRow
          type="flex"
          justify={justify}
          align={align}
          gutter={80}
          reversed={reversed}
        >
          <SectionCol md="6" >
              {reversed ? imageSide : textSide}
          </SectionCol>
          <SectionCol md="6" >
              {reversed ? textSide : imageSide}
          </SectionCol>
        </SectionRow>
      </>
    )
}

SectionSideBySide.propTypes = {
  reversed: PropTypes.bool,
  align: PropTypes.string,
  justify: PropTypes.string,
  maxWidth: PropTypes.string,
  gutter: PropTypes.string,
}

SectionSideBySide.defaultProps = {
  reversed: false,
  align: "middle",
  justify: "space-between",
  maxWidth: "1280px",
}

const SectionRow = styled(Row)`
  flex-direction:row;
  @media (max-width: 576px) {
    ${props =>
      props.reversed
        ? `
          flex-direction: row-reverse;
        `
        : ``};
  }
`

const SectionCol = styled(Col)`
  text-align:left;
  @media (max-width: 991px) {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  @media (max-width: 576px) {
    padding-bottom: 20px;
    width: 100%;
  }
`
